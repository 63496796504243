import { Typography } from '@mui/material';
import { Wrapper } from './styles';

const Home = () => {

  return (
    <Wrapper>
        <Typography variant="h2">GRCHMRX</Typography>
    </Wrapper>
  )
};

export default Home;
